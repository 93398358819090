import Link from 'next/link';
import { HomeIcon, NewspaperIcon, DocumentTextIcon, DevicePhoneMobileIcon, MapIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useActiveLink } from '../context/activelink';

interface SidebarProps {
  menuOpen: boolean;
  handleHeader: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ menuOpen, handleHeader }) => {
  const [theme, setTheme] = useState<'light' | 'dark'>('light');
  const { activeLink, setActiveLink } = useActiveLink();

  const handleClick = (href: string) => {
    setActiveLink(href);
    handleHeader();
  };

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme as 'light' | 'dark');
      document.documentElement.classList.toggle('dark', storedTheme === 'dark');
    } else {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (prefersDark) {
        setTheme('dark');
        document.documentElement.classList.add('dark');
      }
    }

    let deferredPrompt: any;

    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      deferredPrompt = e;
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    const installAppButton = document.getElementById('installApp');
    if (installAppButton) {
      installAppButton.addEventListener('click', async () => {
        if (deferredPrompt) {
          deferredPrompt.prompt();
          const { outcome } = await deferredPrompt.userChoice;
          if (outcome === 'accepted') {
            deferredPrompt = null;
          }
        }
      });
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.classList.toggle('dark', newTheme === 'dark');
    localStorage.setItem('theme', newTheme);
    handleHeader();
  };

  return (
    <div className={`h-full w-56 border-r dark:border-slate-700 pt-20 px-5 bg-white dark:bg-slate-800 absolute top-0 lg:relative lg:hidden transition-transform duration-300 ease-in-out ${menuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'} z-10`}>
      <p className='text-base px-2 font-bold text-blue-800 dark:text-blue-600'>MENU</p>
      <Link href='/' onClick={() => handleClick('/')} className={`p-2 hover:bg-slate-100 dark:hover:bg-slate-700 rounded-full text-base font-medium ${activeLink === '/' ? 'text-blue-500' : 'text-slate-700 dark:text-slate-50'} group cursor-pointer flex items-center`}>
        <HomeIcon className='h-5 stroke-slate-400 dark:stroke-gray-50 mr-4 group-hover:stroke-blue-600' />
        Beranda
      </Link>
      <Link href='/berita' onClick={() => handleClick('/berita')} className={`p-2 hover:bg-slate-100 dark:hover:bg-slate-700 rounded-full text-base font-medium ${activeLink === '/berita' ? 'text-blue-500' : 'text-slate-700 dark:text-slate-50'} group cursor-pointer flex items-center`}>
        <NewspaperIcon className='h-5 stroke-slate-400 dark:stroke-gray-50 mr-4 group-hover:stroke-blue-600' />
        Berita
      </Link>
      <Link href='/artikel' onClick={() => handleClick('/artikel')} className={`p-2 hover:bg-slate-100 dark:hover:bg-slate-700 rounded-full text-base font-medium ${activeLink === '/artikel' ? 'text-blue-500' : 'text-slate-700 dark:text-slate-50'} group cursor-pointer flex items-center`}>
        <DocumentTextIcon className='h-5 stroke-slate-400 dark:stroke-gray-50 mr-4 group-hover:stroke-blue-600' />
        Artikel
      </Link>
      <Link href='/daftar-lengkap-kode-plat-nomor-polisi-kendaraan-daerah-di-indonesia' onClick={() => handleClick('/daftar-lengkap-kode-plat-nomor-polisi-kendaraan-daerah-di-indonesia')} className={`p-2 hover:bg-slate-100 dark:hover:bg-slate-700 rounded-full text-base font-medium ${activeLink === '/daftar-lengkap-kode-plat-nomor-polisi-kendaraan-daerah-di-indonesia' ? 'text-blue-500' : 'text-slate-700 dark:text-slate-50'} group cursor-pointer flex items-center`}>
        <MapIcon className='h-5 stroke-slate-400 dark:stroke-gray-50 mr-4 group-hover:stroke-blue-600' />
        Kode Plat
      </Link>
      <Link href='/daftar-harga-motor-mobil-baru' onClick={() => handleClick('/daftar-harga-motor-mobil-baru')} className={`p-2 hover:bg-slate-100 dark:hover:bg-slate-700 rounded-full text-base font-medium ${activeLink === '/daftar-harga-motor-mobil-baru' ? 'text-blue-500' : 'text-slate-700 dark:text-slate-50'} group cursor-pointer flex items-center`}>
        <DocumentTextIcon className='h-5 stroke-slate-400 dark:stroke-gray-50 mr-4 group-hover:stroke-blue-600' />
        Daftar Harga
      </Link>
      <hr className='m-2'></hr>
      <div id='installApp' className={`p-2 hover:bg-slate-100 dark:hover:bg-slate-700 rounded-full text-base font-medium ${activeLink === '/install' ? 'text-blue-500' : 'text-slate-700 dark:text-slate-50'} group cursor-pointer flex items-center`}>
        <DevicePhoneMobileIcon className='h-5 stroke-slate-400 dark:stroke-gray-50 mr-4 group-hover:stroke-blue-600' />
        Install Aplikasi
      </div>
      <hr className='m-2'></hr>
      <div className='flex justify-between items-center p-2'>
        <span className='text-base font-medium text-slate-700 dark:text-slate-50'>Mode Gelap</span>
        <input type='checkbox' onChange={toggleTheme} checked={theme === 'dark'} className='sr-only peer' id='theme-toggle' />
        <label htmlFor='theme-toggle' className="flex items-center cursor-pointer relative w-8 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[0px] after:start-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
        </label>
      </div>
    </div>
  );
};

export default Sidebar;
